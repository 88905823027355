import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import SubpageHero from "../components/subpage-hero";
import TextImage from "../components/text-image";
import Seo from "../components/seo";
import NewsletterSection from "../components/newsletter-section";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Seo title="Kontakt do Fundacji Enjoy The Ride" mdxType="Seo" />
    <SubpageHero image="/illustrations/contact.svg" mdxType="SubpageHero">
      <h1>{`Kontakt z fundacją`}</h1>
      <p>{`Nam adipiscing. Pellentesque auctor neque nec urna. Aliquam erat volutpat. Vestibulum eu odio. Praesent turpis.`}</p>
    </SubpageHero>
    <section className="etr-container mb-16 lg:mb-40">
  <div className="grid md:grid-cols-2 gap-x-10">
    <div className="flex flex-col justify-center leading-8  max-w-content-plus mb-16 md:ml-auto w-full">
      <h2 className="text-2xl sm:text-3xl font-tiempos mb-6">
        Fundacja Enjoy The Ride
      </h2>
      <p>
        ul. Marii Konopnickiej 14 / 8<br />
        15-215 Białystok, Polska
        <br />
        (Nie udzielamy pomocy stacjonarnie)
      </p>
      <p>
        KRS: 0000875617
        <br />
        NIP: 5423422076
        <br />
        REGON: 387784214
      </p>
      <p>
        Sąd Rejonowy w Białymstoku
        <br />
        XII Wydział Gospodarczy Krajowego Rejestru Sądowego
      </p>
    </div>
    <div className="max-w-content-plus w-full">
      <section className="mb-12">
        <h3 className="text-2xl sm:text-3xl font-tiempos mb-6">Numer konta</h3>
        <p>mBank 12 1120 1234 0000 1234 5678 9123</p>
      </section>
      <section>
        <h3 className="text-2xl sm:text-3xl font-tiempos mb-6">Dane kontaktowe</h3>
        <p>Email: <a className="text-green-500 font-bold" href="mailto:kontakt@enjoytheride.pl">kontakt@enjoytheride.pl</a></p>
        <p>Tel: <a className="text-green-500 font-bold" href="tel:+48789456123">+48 789 456 123</a></p>
      </section>
    </div>
  </div>
    </section>
    <NewsletterSection mdxType="NewsletterSection" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      